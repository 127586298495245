<template>
  <div>
    <img :src="home_bg" class="home_bg" />
    <!-- <el-image :src="home_bg" lazy class="home_bg"></el-image> -->
    <div class="home_first">
      <div class="home_first_bg">
        <div class="home_first_introduction">
          <div class="home_first_introduction_name">
            重塑中医药产业链 创新大健康新生态
          </div>
          <div class="home_first_introduction_content">
            所谓道地药材，又称地道药材，是优质纯真药材的专用名词，<br />
            它是指历史悠久、产地适宜、品种优良、产量宏丰、炮制考究、疗效突出、带有地域特点的药材。<br />
            如甘肃的当归，宁夏的枸杞，青海的大黄，内蒙的黄芪，东北的人参、细辛、五味子，山西的党参，河南的地黄、牛膝、山药、菊花等<br />
            中药的采收时节和方法对确保药物的质量有着密切的关联。因为动植物在其生长发育的不同时期其药用部分所含有效及有害成分各不相同，<br />
            因此药物的疗效和毒副作用也往往有较大差异，故药材的采收必须在适当的时节采集。
          </div>
        </div>
        <div class="home_second">
          <div class="home_second_bg">
            <div class="home_second_list">
              <div class="home_second_list_img newsImgCarousel" v-if="newsList0.length > 0" ref="newsImgCarousel" style="position: relative;">
                <img src="../../static/newImg/img_loading.png" class="img_loading" v-show="imgSuffix"/>
                <img :src="newsList0[newsListIndex].newsCover + imgSuffix" width="640px" height="366px" style="display: block"
                  @click="
                    toPage('newsListDetail', {
                      id: newsList0[newsListIndex].newsId,
                    })
                    " />
                <div class="home_second_list_img_tips">
                  <div class="home_second_list_img_tips_title" @click="
                    toPage('newsListDetail', {
                      id: newsList0[newsListIndex].newsId,
                    })
                    ">
                    <div>{{ newsList0[newsListIndex].newsTitle }}</div>
                    <div>
                      {{ newsList0[newsListIndex].newsAbstract }}
                    </div>
                  </div>
                  <div class="home_second_list_img_tips_pointerArr">
                    <div :class="newsListIndex == index
                        ? 'home_second_list_img_tips_pointer active'
                        : 'home_second_list_img_tips_pointer'
                      " v-for="(item, index) in 4" :key="'pointer' + item" @click="newsListIndex = index">
                      {{ index + 1 }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="home_second_list_right">
                <div v-for="item in newsList" :key="item.id" class="home_second_list_right_news hover-b"
                  @click="toPage('newsListDetail', { id: item.newsId })">
                  <div class="home_second_list_right_news_item">
                    <div class="home_second_list_right_news_time">
                      {{ item.year }}<br />
                      <div class="home_second_list_right_news_time_day">
                        {{ item.day }}
                      </div>
                    </div>
                    <div class="home_second_list_right_news_content">
                      <div class="home_second_list_right_news_name">
                        {{ item.title }}
                      </div>
                      <div class="home_second_list_right_news_value">
                        {{
                          item.summary
                            ? item.summary
                            : "中峪道地药材在产业振兴精准扶贫共同富裕之路上稳健前行"
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home_first_about">
          <div class="home_first_about_img">
            <div class="swiper-container swiper-container1">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in newsList1" :key="item.id"
                  style="height: 200px; overflow: hidden">
                  <img src="../../static/newImg/img_loading.png" class="img_loading" v-show="imgSuffix"/>
                  <img class="home_all_module_img" :src="item.newsCover + imgSuffix" style="width: 100%; "
                    @click="toDetails(item)" />
                </div>
              </div>
              <div class="swiper-pagination swiper-pagination1"></div>
            </div>
            <div class="home_first_about_img_list">
              <div class="home_first_about_img_list_name moveTop" v-for="item in newsList1" :key="item.newsId"
                @click="toDetails(item)">
                {{ item.newsTitle }}
              </div>
            </div>
          </div>
          <div class="home_first_about_img">
            <div class="swiper-container swiper-container2">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in newsList2" :key="item.id"
                  style="height: 200px; overflow: hidden">
                  <img src="../../static/newImg/img_loading.png" class="img_loading" v-show="imgSuffix"/>
                  <img class="home_all_module_img" :src="item.newsCover + imgSuffix" style="width: 100%; "
                    @click="toDetails(item)" />
                </div>
              </div>
              <div class="swiper-pagination swiper-pagination2"></div>
            </div>
            <div class="home_first_about_img_list">
              <div class="home_first_about_img_list_name moveTop" v-for="item in newsList2" :key="item.newsId"
                @click="toDetails(item)">
                {{ item.newsTitle }}
              </div>
            </div>
          </div>
          <div class="home_first_about_img">
            <div class="swiper-container swiper-container3">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in newsList3" :key="item.id"
                  style="height: 200px; overflow: hidden">
                  <img src="../../static/newImg/img_loading.png" class="img_loading" v-show="imgSuffix"/>
                  <img class="home_all_module_img" :src="item.newsCover + imgSuffix" style="width: 100%; "
                    @click="toDetails(item)" />
                </div>
              </div>
              <div class="swiper-pagination swiper-pagination3"></div>
            </div>
            <div class="home_first_about_img_list">
              <div class="home_first_about_img_list_name moveTop" v-for="item in newsList3" :key="item.newsId"
                @click="toDetails(item)">
                {{ item.newsTitle }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home_map">
      <div class="home_map_bg">
        <div class="home_map_int">
          <div class="home_map_int_title">中峪道地药材基地分布</div>
          <div class="home_map_int_content">
            中峪道地药材着眼于全国34省市区根据地区药材古籍记载和市场需求因地选材，建立各级省级药材公司，负责该省市区的大品类道地药材产业。
          </div>
        </div>
        <div class="home_map_img">
          <img src="../../static/newImg/位图6.png" width="760px" />
          <div class="home_map_img_point" style="right: 140px; bottom: 160px" v-if="areaArr.indexOf('上海省') > -1">
            上海
          </div>
          <div class="home_map_img_point" style="right: 160px; bottom: 120px" v-if="areaArr.indexOf('浙江省') > -1">
            浙江
          </div>
          <div class="home_map_img_point" style="right: 170px; bottom: 90px" v-if="areaArr.indexOf('福建省') > -1">
            福建
          </div>
          <div class="home_map_img_point" style="right: 240px; bottom: 140px" v-if="areaArr.indexOf('湖北省') > -1">
            湖北
          </div>
          <div class="home_map_img_point" style="right: 350px; bottom: 150px" v-if="areaArr.indexOf('四川省') > -1">
            四川
          </div>
          <div class="home_map_img_point" style="right: 300px; bottom: 130px" v-if="areaArr.indexOf('重庆省') > -1">
            重庆
          </div>
          <div class="home_map_img_point" style="right: 240px; bottom: 250px" v-if="areaArr.indexOf('山西省') > -1">
            山西
          </div>
          <div class="home_map_img_star" style="right: 120px; bottom: 140px; color: #ff0011">
            ★ 集团总部
          </div>
          <div class="home_map_img_tips" v-if="treeData[areaIndex]" :style="'right: ' +
            (areaPosition[areaIndex].right + 65) +
            'px;bottom: ' +
            (areaPosition[areaIndex].bottom - 63) +
            'px;'
            ">
            {{
              treeData[areaIndex].companyDesc.length > 110
                ? treeData[areaIndex].companyDesc.substring(0, 110) + "..."
                : treeData[areaIndex].companyDesc
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="home_four">
      <div class="home_four_module" v-for="(item, index) in treeData" :key="item.companyId">
        <img :src="item.companyImage + imgSuffix" width="100%" height="300px" />
        <div class="home_four_module_bg" @click="
          toPage('/base', { companyId: item.companyId, type: 0, item, index })
          "></div>
        <div class="home_four_module_name" @click="
          toPage('/base', { companyId: item.companyId, type: 0, item, index })
          ">
          {{ item.companyName }}
        </div>
        <div class="home_four_module_newsList">
          <div class="home_four_module_newsList_name moveTop" v-for="item1 in item.newsList" :key="item1.newsId"
            @click="toDetails(item1, item)">
            {{ item1.newsTitle }}
          </div>
        </div>
      </div>
    </div>
    <div class="home_third">
      <div class="home_third_bg">
        <div class="home_third_title">药材合集</div>
        <div class="home_third_list">
          <div class="swiper-container swiper-container4">
            <div class="swiper-wrapper">
              <div class="swiper-slide home_third_list_item" @click="toBusiness(0)" data-name="0">
                <img src="../../static/image/薏苡仁.jpg" width="288.5px" />
                <div class="home_third_list_item_name">
                  <div class="home_third_list_item_name_1">关药</div>
                  <div class="home_third_list_item_name_2">
                    主产地山海关以北、东北三省及内蒙古东部。如人参、鹿茸、细辛、辽五味子、防风、关黄柏、龙胆、平贝母、刺五加、升麻、蛤蟆油、甘草、麻黄、黄芪、赤芍、苍术等。
                  </div>
                </div>
              </div>
              <div class="swiper-slide home_third_list_item" @click="toBusiness(1)" data-name="1">
                <img src="../../static/image/小茴香.jpg" width="288.5px" />
                <div class="home_third_list_item_name">
                  <div class="home_third_list_item_name_1">北药</div>
                  <div class="home_third_list_item_name_2">
                    主产地河北、山东、山西及内蒙古中部。如党参、酸枣仁、柴胡、白芷、北沙参、板蓝根、大青叶、青黛、黄芩、香附、知母、山楂、金银花、连翘、桃仁、苦杏仁、薏苡仁、小茴香、大枣等。
                  </div>
                </div>
              </div>
              <div class="swiper-slide home_third_list_item" @click="toBusiness(2)" data-name="2">
                <img src="../../static/image/红花.jpg" width="288.5px" />
                <div class="home_third_list_item_name">
                  <div class="home_third_list_item_name_1">怀药</div>
                  <div class="home_third_list_item_name_2">
                    主产地河南，如著名的四大怀药-地黄、牛膝、山药、菊花；天花粉、瓜蒌、白芷、辛夷、红花、金银花、山茱萸等。
                  </div>
                </div>
              </div>
              <div class="swiper-slide home_third_list_item" @click="toBusiness(4)" data-name="4">
                <img src="../../static/image/菊花.jpg" width="288.5px" />
                <div class="home_third_list_item_name">
                  <div class="home_third_list_item_name_1">浙药</div>
                  <div class="home_third_list_item_name_2">
                    主产地浙江，如著名的浙八味-浙贝母、白术、延胡索、山茱萸、玄参、杭白芍、杭菊花、杭麦冬；温郁金、莪术、杭白芷、栀子、乌梅、乌梢蛇等。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home_third_int">
          中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Pagination, Autoplay } from "swiper";
import axios from "axios";
import { getCompanyList } from "@/api/index.js";
import { getAccessToken, getSignature } from '@/api/index'
export default {
  name: "index",
  data() {
    return {
      imgSuffixTime: null,
      imgSuffix: '?x-oss-process=image/resize,m_lfit,w_300',
      home_bg: require("../../static/image/home_bg.png"),
      medicineList: [
        {
          name: "川药",
          value:
            "主要产地四川、西藏等，如川贝母、川芎、黄连。川乌、附子、麦冬、丹参、干姜、白芷、天麻、川牛膝、川楝子、川楝皮、川续断、花椒、黄柏、厚朴、金钱草、五倍子、冬虫夏草、麝香等。",
        },
        {
          name: "广药",
          value:
            "主产地广东、广西、海南及台湾。如阳春砂、广藿香、广金钱草、益智仁、广陈皮、广豆根、蛤蚧、肉桂、桂莪术、苏木、巴戟天、高良姜、八角茴香、化橘红、樟脑、桂枝、槟榔等。",
        },
        {
          name: "云药",
          value:
            "主产地云南。如三七、木香、重楼、茯苓、萝芙木、诃子、草果、马钱子、儿茶等。",
        },
        {
          name: "贵药",
          value:
            "主产地贵州。如天冬、天麻、黄精、杜仲、吴茱萸、五倍子、朱砂等。",
        },
        {
          name: "怀药",
          value:
            "主产地河南，如著名的四大怀药-地黄、牛膝、山药、菊花；天花粉、瓜蒌、白芷、辛夷、红花、金银花、山茱萸等。",
        },
        {
          name: "浙药",
          value:
            "主产地浙江，如著名的浙八味-浙贝母、白术、延胡索、山茱萸、玄参、杭白芍、杭菊花、杭麦冬；温郁金、莪术、杭白芷、栀子、乌梅、乌梢蛇等。",
        },
        {
          name: "关药",
          value:
            "主产地山海关以北、东北三省及内蒙古东部。如人参、鹿茸、细辛、辽五味子、防风、关黄柏、龙胆、平贝母、刺五加、升麻、蛤蟆油、甘草、麻黄、黄芪、赤芍、苍术等。",
        },
        {
          name: "北药",
          value:
            "主产地河北、山东、山西及内蒙古中部。如党参、酸枣仁、柴胡、白芷、北沙参、板蓝根、大青叶、青黛、黄芩、香附、知母、山楂、金银花、连翘、桃仁、苦杏仁、薏苡仁、小茴香、大枣、香加皮、阿胶、全蝎、土鳖虫、滑石、代赭石等。",
        },
        {
          name: "华南药",
          value:
            "主要产地长江以南，南岭以北。如茅苍术、南沙参、太子参、明党参、枳实、枳壳、牡丹皮、木瓜、乌梅、艾叶、薄荷、龟板、鳖甲、蟾蜍、蜈蚣、蕲蛇、石膏、泽泻、莲子、玉竹等。",
        },
        {
          name: "西北药",
          value:
            "主产地丝绸之路的起点西安以西的广大地区（陕、甘、宁、青、新及内蒙古西部）。如大黄、当归、秦艽、秦皮、羌活、枸杞子、银柴胡、党参、紫草、阿魏等。",
        },
        {
          name: "藏药",
          value:
            "主产地青藏高原地区。如著名的四大藏药-冬虫夏草、雪莲花、炉贝母、藏红花，甘松、胡黄连、藏木香、藏菖蒲、余甘子、毛诃子、麝香等。",
        },
      ],
      newsList: [
        {
          id: 0,
          name: "为进一步加强新昌与中峪集团…",
          value:
            "生物制药坚持中医药原创思维，深入发掘中医药精华，是一家传承古代经典名方、名老中医经验方、传统民族药业、新药研发为一体的高新技术制药研发公司…",
          time: "10-01",
        },
        {
          id: 1,
          name: "为进一步加强新昌与中峪集团…",
          value:
            "生物制药坚持中医药原创思维，深入发掘中医药精华，是一家传承古代经典名方、名老中医经验方、传统民族药业、新药研发为一体的高新技术制药研发公司…",
          time: "10-01",
        },
        {
          id: 2,
          name: "为进一步加强新昌与中峪集团…",
          value:
            "生物制药坚持中医药原创思维，深入发掘中医药精华，是一家传承古代经典名方、名老中医经验方、传统民族药业、新药研发为一体的高新技术制药研发公司…",
          time: "10-01",
        },
        {
          id: 3,
          name: "为进一步加强新昌与中峪集团…",
          value:
            "生物制药坚持中医药原创思维，深入发掘中医药精华，是一家传承古代经典名方、名老中医经验方、传统民族药业、新药研发为一体的高新技术制药研发公司…",
          time: "10-01",
        },
      ],
      mapList: [
        {
          name: "四川",
        },
        {
          name: "浙江",
        },
      ],
      mapIndex: 0,
      scrollInterval1: null,
      scrollInterval2: null,
      scrollInterval3: null,
      activeIndex: 0,
      treeData: [],
      newsList1: [],
      newsList2: [],
      newsList3: [],
      newsList0: [],
      newsListIndex: 0,
      areaArr: [],
      areaPosition: [],
      areaIndex: 0,
      swiperImg1: null,
      swiperImg2: null,
      swiperImg3: null,
      slideBottom: null,
    };
  },
  mounted() {
    this.getWXInfo()
    this.imgSuffixTime = setTimeout(()=>{
      this.imgSuffix = ''
    }, 4000)
    Swiper.use([Pagination, Autoplay]);
    window.scroll(0, 0);
    this.getList(18);
    this.getCompanyList();
    const that = this;
    this.slideBottom = new Swiper(".swiper-container4", {
      initialSlide: 0,
      spaceBetween: 20,
      slidesPerView: 4,
      observer: true, //开启动态检查器，监测swiper和slide
      observeParents: true, //监测Swiper 的祖/父元素
      loop: true,
      on: {
        click: function (e) {
          if (e.clickedIndex == 8) {
            that.toBusiness(0);
          }
          if (e.clickedIndex == 9) {
            that.toBusiness(1);
          }
          if (e.clickedIndex == 10) {
            that.toBusiness(2);
          }
          if (e.clickedIndex == 11) {
            that.toBusiness(4);
          }
          // this.toBusiness(name)
        },
      },
    });
    // this.slideBottom.slideTo(0)
    this.scrollInterval1 = setInterval(() => {
      this.$nextTick(() => {
        this.slideBottom.slideNext(1800);
      });
    }, 2000);
    document
      .getElementsByClassName("home_third_list")[0]
      .addEventListener("mouseover", () => {
        clearInterval(this.scrollInterval1);
      });
    document
      .getElementsByClassName("home_third_list")[0]
      .addEventListener("mouseleave", () => {
        this.slideBottom.slideNext(1800);
        this.scrollInterval1 = setInterval(() => {
          this.$nextTick(() => {
            this.slideBottom.slideNext(1800);
          });
        }, 2000);
      });
  },
  beforeDestroy() {
    clearInterval(this.scrollInterval1);
    clearInterval(this.scrollInterval2);
    clearInterval(this.scrollInterval3);
    clearTimeout(this.imgSuffixTime)
  },
  methods: {
    getWXInfo() {
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp
        const nonceStr = res.data.noncestr
        const signature = res.data.signature
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxad8dd02ae97d307f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        const obj = {
            title: '中峪农业', // 分享标题
            desc: '浙江中峪农业发展有限责任公司（简称“中峪农业”），秉持以绿水青山就是金山银山的发展理念，传承发展中医药产业为己任的宗旨，以数字化改革和乡村振兴为初心，致力于在农业领域推动创新数字化发展，专注于道地药材从源头孵化与保护产业生态，带动地方就业、加工、仓储物流等，推动地方文旅、科研、产教研学、人才培养等多元化产业发展；同时积极探索药、风、光、零碳、能源产业带的同步推进。在集团内循环产业链赋能下，跨界融合部署，遵循国家政策，紧跟国家战略，以“数字经济新农业 乡村振兴新载体”的创新产业布局，致力于实现乡村产业振兴和多维推动共同富裕，打造数字化新农业的全新产业标杆。', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-07-08/f7290d79ae584ffe86229f147556ad8a.jpg', // 分享图标
          }
          wx.updateAppMessageShareData(obj)
          wx.updateTimelineShareData(obj)
        });
      })
    },
    toBusiness(tabIndex) {
      this.$router.push({
        path: "/business",
        query: { tabIndex },
      });
    },
    toDetails(item, item1) {
      if (item1) {
        this.$router.push({
          path: "/companyNewsListDetail",
          query: {
            id: item.newsId,
            name1: "首页",
            name2: item1.companyName,
            id2: item1.companyId,
          },
        });
      } else {
        this.$router.push({
          path: "/newsListDetail",
          query: { id: item.newsId },
        });
      }
    },
    getCompanyList() {
      //记得
      getCompanyList({ current: 1, size: 4, pid: 3 }).then((res) => {
        this.treeData = res.data.list;
        this.treeData.forEach((val) => {
          if (
            this.areaArr.indexOf(val.companyLocation) == -1 &&
            val.companyLocation
          ) {
            this.areaArr.push(val.companyLocation);
          }
          this.pushPosition(val);
          getCompanyList({ current: 1, size: 100, pid: val.companyId }).then(
            (res1) => {
              const treeChildData = res1.data.list;
              treeChildData.forEach((val1) => {
                getCompanyList({
                  current: 1,
                  size: 100,
                  pid: val1.companyId,
                }).then((res2) => {
                  this.$set(val1, "treeChildData", res2.data.list);
                });
              });
              this.$set(val, "treeChildData", res1.data.list);
            }
          );

          axios
            .get(
              `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=4&companyId=${val.companyId}&websiteCategoryId=33`
            )
            .then(async (res) => {
              this.$set(val, "newsList", res.data.data.list);
            });
        });
        this.$nextTick(() => {
          new Swiper(".swiper-container0", {});
        });
      });
    },
    pushPosition(val) {
      if (val.companyLocation == "山西省") {
        this.areaPosition.push({ right: 240, bottom: 250 });
      }
      if (val.companyLocation == "重庆省") {
        this.areaPosition.push({ right: 300, bottom: 130 });
      }
      if (val.companyLocation == "四川省") {
        this.areaPosition.push({ right: 350, bottom: 150 });
      }
      if (val.companyLocation == "湖北省") {
        this.areaPosition.push({ right: 240, bottom: 140 });
      }
      if (val.companyLocation == "福建省") {
        this.areaPosition.push({ right: 170, bottom: 90 });
      }
      if (val.companyLocation == "浙江省") {
        this.areaPosition.push({ right: 160, bottom: 120 });
      }
      if (val.companyLocation == "上海省") {
        this.areaPosition.push({ right: 140, bottom: 160 });
      }
    },
    getList(categoryId) {
      axios
        //记得修改
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=100&websiteId=3`
        )
        .then(async (res) => {
          console.log(res);
          const content = res.data.data.list;
          content.forEach((item) => {
            const updateTime = item.publishTime.split("-");
            item.day = updateTime[1] + "-" + updateTime[2];
            item.year = updateTime[0];
            item.title =
              item.newsTitle.length > 30
                ? item.newsTitle.substring(0, 30) + "..."
                : item.newsTitle;
          });
          this.newsList = res.data.data.list.slice(0, 4);
          this.newsList1 = res.data.data.list.slice(0, 4);
          this.newsList2 = res.data.data.list.slice(5, 9);
          this.newsList3 = res.data.data.list.slice(10, 14);
          this.newsList0 = res.data.data.list.slice(0, 4);
          this.$nextTick(() => {
            this.swiperImg1 = new Swiper(".swiper-container1", {
              observer: true, //开启动态检查器，监测swiper和slide
              observeParents: true, //监测Swiper 的祖/父元素
              pagination: {
                el: ".swiper-pagination1",
              },
            });
            this.swiperImg2 = new Swiper(".swiper-container2", {
              observer: true, //开启动态检查器，监测swiper和slide
              observeParents: true, //监测Swiper 的祖/父元素
              pagination: {
                el: ".swiper-pagination2",
              },
            });
            this.swiperImg3 = new Swiper(".swiper-container3", {
              observer: true, //开启动态检查器，监测swiper和slide
              observeParents: true, //监测Swiper 的祖/父元素
              pagination: {
                el: ".swiper-pagination3",
              },
            });
          });
          this.scrollInterval2 = setInterval(() => {
            if (this.newsListIndex < 4) {
              this.newsListIndex += 1;
            } else {
              this.newsListIndex = 0;
            }
          }, 8000);
          this.scrollInterval3 = setInterval(() => {
            if (this.areaIndex == this.areaArr.length - 1) {
              this.areaIndex = 0;
            } else {
              this.areaIndex += 1;
            }
          }, 8000);
          this.$nextTick(() => {
            if (document.querySelector(".newsImgCarousel")) {
              this.slideBanner();
            }
          });
        });
    },
    toPage(path, query) {
      if (query.type == 0) {
        if (path == "/base") {
          if (query.item.companyCategory == 1) {
            this.$router.push({ path: "/baseOther", query });
          } else {
            this.$router.push({ path, query });
          }
        } else {
          let len = 0;
          this.treeData.forEach((val, ind) => {
            if (ind < query.index) {
              len += val.treeChildData.length;
            }
          });
          this.$router.push({
            path,
            query: { companyId: query.item.companyId, index: len },
          });
        }
      } else {
        this.$router.push({ path, query });
      }
    },
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector(".newsImgCarousel");
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener("touchend", function (e) {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          if (that.newsListIndex < 4) {
            that.newsListIndex += 1;
          } else {
            that.newsListIndex = 0;
          }
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          if (that.newsListIndex > 0) {
            that.newsListIndex -= 1;
          } else {
            that.newsListIndex = 4;
          }
          return;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.home_bg {
  width: 100%;
  display: block;
}



.home_first {
  width: 100%;
  height: 1350px;
  background: url("../../static/newImg/位图2.png") no-repeat;
  background-size: cover;
  position: relative;

  .home_first_bg {
    width: 1280px;
    margin: 0 auto;
    padding: 50px 0;
    position: absolute;
    top: -200px;
    left: calc((100% - 1280px) / 2);

    .home_first_introduction {
      width: 1080px;
      height: 250px;
      background: url("https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/c2d82663d81f4324b5fbd2f1905e1c94.png") no-repeat;
      background-size: 100%;
      background-position: left bottom;
      text-align: center;
      padding: 54px 100px;

      .home_first_introduction_name {
        font-size: 24px;
        font-weight: 400;
        color: #565759;
        line-height: 33px;
        letter-spacing: 15px;
      }

      .home_first_introduction_content {
        margin-top: 35px;
        font-size: 16px;
        font-weight: 400;
        color: #4c4545;
        line-height: 31px;
        letter-spacing: 1px;
      }

      .home_first_introduction_detail {
        cursor: pointer;
        width: 110px;
        height: 40px;
        background: linear-gradient(288deg,
            #56a24c 0%,
            #32663b 100%,
            #32663b 100%);
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        margin-top: 27px;
        margin-left: 485px;
      }

      .home_first_introduction_detail:hover {
        background: linear-gradient(288deg,
            #32663b 50%,
            #32663b 100%,
            #32663b 100%);
      }
    }

    .home_second_introduction {
      width: 1280px;
      margin-top: 55px;
      overflow: auto;
      white-space: nowrap;

      .home_second_introduction_type {
        cursor: pointer;
        margin-right: 24px;
        display: inline-block;
        padding: 10px;
        width: 280px;
        height: 240px;
        border: 1px solid rgba(53, 74, 50, 0.23);
        white-space: normal;
        vertical-align: middle;

        .home_second_introduction_type_bg {
          height: 180px;
          padding: 30px 26px;
          background: rgba(53, 74, 50, 0.06);

          .home_second_introduction_type_bg_name {
            text-align: center;
            font-size: 36px;
            font-weight: 400;
            color: #354a32;
            line-height: 50px;
          }

          .home_second_introduction_type_bg_value {
            margin-top: 24px;
            font-size: 12px;
            font-weight: 400;
            color: #565759;
            line-height: 17px;
          }
        }
      }
    }

    .home_third_introduction {
      margin-top: 70px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #354a32;
      line-height: 32px;
      letter-spacing: 2px;
      padding: 0 150px;
      width: calc(100% -300px);
    }

    .home_first_about {
      width: 1240px;
      padding: 0 20px;
      display: flex;

      .home_first_about_img {
        overflow: hidden;
        margin-left: 15px;
        width: 416px;

        img {
          display: block;
          cursor: pointer;
        }

        .home_first_about_img_list {
          border: 1px solid rgba(53, 74, 50, 0.1);
          border-top: 0;
          line-height: 62px;
          padding: 0 24px;

          .home_first_about_img_list_name {
            position: relative;
            padding-left: 20px;
            color: #595959;
            border-bottom: 1px dashed #cbcbcb;
            white-space: nowrap;
            /* 防止文本换行 */
            overflow: hidden;
            /* 控制文本溢出时隐藏超出部分 */
            text-overflow: ellipsis;
            cursor: pointer;
          }

          .home_first_about_img_list_name:last-child {
            border: none;
          }

          .home_first_about_img_list_name::before {
            content: "";
            background-color: #595959;
            width: 5px;
            height: 5px;
            border-radius: 0;
            position: absolute;
            left: 0;
            top: 28px;
          }
        }
      }

      .home_first_about_img:first-child {
        margin-left: 0;
      }
    }
  }

  .home_first_title {
    width: 100%;
    line-height: 40px;

    .home_first_title_zn {
      font-family: 宋体;
      font-size: 20px;
      font-weight: bold;
      color: #1d1b19;
      display: inline-block;
      position: relative;
    }

    .home_first_title_zn::after {
      content: "";
      position: absolute;
      top: 42px;
      left: 0;
      width: 40px;
      height: 2px;
      background: #9e1307;
    }

    .home_first_title_en {
      cursor: pointer;
      font-size: 14px;
      color: #4d5461;
      float: right;
    }

    .home_first_title_line {
      width: 100%;
      height: 1px;
      background: #e5e5e5;
    }

    .home_first_title_list {
      border-bottom: 1px dashed #abaeb0;
      display: flex;
      height: 67px;
      align-items: center;
      cursor: pointer;

      .home_first_title_list_data {
        margin-right: 15px;

        .home_first_title_list_data_day {
          font-size: 16px;
          color: #9e1307;
          line-height: 0px;
          text-align: center;
        }

        .home_first_title_list_data_year {
          font-size: 8px;
          font-weight: 400;
          color: #9e1307;
          line-height: 11px;
        }
      }

      .home_first_title_list_summary {
        font-size: 14px;
        color: #1d1b19;
        line-height: 67px;
      }

      .home_first_title_list_right {
        line-height: 67px;
        margin-left: auto;
      }
    }
  }
}

@media (max-width: 767px) {
  .home_first {
    height: 1350px;

    .home_first_bg {
      padding: 0;
      top: -100px;
    }
  }
}


.home_map {
  width: 100%;
  height: 800px;
  background: url("../../static/newImg/位图5.jpg") no-repeat;
  background-size: cover;
  position: relative;

  .home_map_bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .home_map_int {
      width: 400px;
      margin-right: 20px;

      .home_map_int_title {
        font-size: 34px;
        font-weight: 800;
        color: #ffffff;
        line-height: 41px;
        letter-spacing: 6px;
      }

      .home_map_int_content {
        margin-top: 46px;
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        line-height: 29px;
        letter-spacing: 4px;
      }
    }

    .home_map_img {
      position: relative;

      .home_map_img_point,
      .home_map_img_star,
      .home_map_img_tips,
      .home_map_img_line {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 31px;
        padding-left: 15px;
      }

      .home_map_img_point:hover,
      .home_map_img_star:hover {
        transform: scale(1.3);
      }

      .home_map_img_tips {
        background-color: #000000b7;
        padding: 20px;
        color: #fff;
        width: 340px;
        height: 150px;
        font-size: 14px;
        line-height: 30px;
        opacity: 0;
        animation: changetips 8s linear infinite;
      }

      @keyframes changetips {
        1% {
          opacity: 0;
        }

        10% {
          opacity: 1;
        }

        91% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }

      .home_map_img_tips::before {
        content: " ";
        width: 7px;
        height: 7px;
        background-color: #fff;
        display: block;
        position: absolute;
        right: -5px;
        top: 49px;
        animation: changepoint 8s linear infinite;
      }

      @keyframes changepoint {
        10% {
          opacity: 0;
        }

        20% {
          opacity: 1;
        }

        100% {
          opacity: 1;
        }
      }

      .home_map_img_tips::after {
        content: " ";
        width: 0;
        height: 0;
        border-right: 1px solid #fff;
        border-top: 1px solid #fff;
        display: block;
        position: absolute;
        right: -30px;
        bottom: 77px;
        transform: rotate(0);
        transform-origin: top right;
        animation: changewidth 8s linear infinite;
      }

      @keyframes changewidth {
        10% {
          width: 0;
          height: 60px;
        }

        15% {
          width: 30px;
          height: 60px;
        }

        100% {
          width: 30px;
          height: 60px;
        }
      }

      .home_map_img_point::before {
        content: "";
        background-color: #ffffff;
        width: 7px;
        height: 7px;
        position: absolute;
        left: 0;
        top: 12px;
      }
    }
  }
}

.home_second {
  width: 1240px;
  padding: 0 20px;
  height: 450px;

  .home_second_bg {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .home_second_title {
    margin-top: 80px;
    font-family: 宋体;
    font-size: 36px;
    font-weight: 400;
    color: #202020;
    line-height: 50px;
  }

  .home_second_list {
    display: flex;
    margin-top: 100px;
    position: relative;

    .home_second_list_img {
      cursor: pointer;

      .home_second_list_img_tips {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.54);
        width: 610px;
        height: 30px;
        display: flex;
        padding: 15px;
        align-items: center;
        justify-content: space-between;

        .home_second_list_img_tips_title {
          text-align: left;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          line-height: 30px;

          div:first-child {
            font-size: 16px;
            font-weight: 600;
            width: 400px;
            white-space: nowrap;
            /* 防止文本换行 */
            overflow: hidden;
            /* 控制文本溢出时隐藏超出部分 */
            text-overflow: ellipsis;
          }
        }

        .home_second_list_img_tips_pointerArr {
          display: flex;
          height: 20px;
          align-items: flex-start;

          .home_second_list_img_tips_pointer {
            float: left;
            color: #354a32;
            width: 20px;
            height: 18px;
            position: relative;
            line-height: 18px;
            border: 1px solid #ffffff;
            text-align: center;
            margin-right: 5px;
            cursor: pointer;
            background: #ffffff9a;
            font-size: 14px;
            font-weight: bold;
          }

          .active {
            color: #fff;
            background: #354a32;
            border: 1px solid #ffffff;
          }
        }
      }
    }

    .home_second_list_right {
      margin-left: 60px;
      text-align: left;

      .home_second_list_right_news {
        transition: all 0.5s ease-out;
        padding: 0 10px;
        margin-top: 20px;
        cursor: pointer;

        .home_second_list_right_news_item {
          display: flex;
          align-items: center;
        }

        .home_second_list_right_news_time {
          font-size: 33px;
          font-weight: 400;
          color: #354a32;
          line-height: 35px;
          font-family: 宋体;

          .home_second_list_right_news_time_day {
            font-size: 12px;
            line-height: 25px;
            text-align: center;
          }
        }

        .home_second_list_right_news_content {
          margin-left: 30px;
        }

        .home_second_list_right_news_name {
          font-size: 18px;
          font-family: 宋体;
          font-weight: 400;
          color: #354a32;
          line-height: 25px;
          margin-top: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          // 只要超过宽度就换行，不论中文还是英文
          word-break: break-all;
          //最多展示两行
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .home_second_list_right_news_value {
          margin: 10px 0;
          font-size: 14px;
          font-family: 宋体;
          font-weight: 400;
          color: #a8a8a8;
          line-height: 23px;
          letter-spacing: 1px;
          overflow: hidden;
          text-overflow: ellipsis;
          // 只要超过宽度就换行，不论中文还是英文
          word-break: break-all;
          //最多展示两行
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }

      .home_second_list_right_news:first-child {
        margin-top: 0;
      }
    }
  }
}

.home_third {
  width: 100%;
  background: url("../../static/newImg/位图1.png") no-repeat;
  background-size: cover;
  padding: 50px 0;

  .home_third_bg {
    width: 1280px;
    margin: 0 auto;
  }

  .home_third_title {
    margin-top: 50px;
    font-family: 宋体;
    font-weight: 400;
    color: #202020;
    line-height: 50px;
    text-align: center;
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 7px;
  }

  .home_third_int {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 32px;
    letter-spacing: 2px;
    padding: 0 80px;
  }

  .home_third_list {
    margin-top: 50px;
    padding: 45px 33px;
    display: flex;
    justify-content: space-between;

    .home_third_list_item {
      cursor: pointer;
      transition: all 0.5s ease-out;
      position: relative;

      .home_third_list_item_name {
        background: linear-gradient(180deg,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0.82) 100%);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        font-family: 宋体;
        font-weight: bold;
        font-weight: 400;
        color: #ffffff;

        .home_third_list_item_name_1 {
          font-size: 36px;
          line-height: 50px;
          margin-top: 20px;
        }

        .home_third_list_item_name_2 {
          font-size: 12px;
          line-height: 17px;
          text-align: left;
          padding: 20px 20px 0 20px;
        }
      }
    }
  }
}

.home_four {
  width: 1240px;
  padding: 0 20px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .home_four_module {
    width: calc(50% - 20px);
    margin-top: 40px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);

    .home_four_module_bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 620px;
      height: 300px;
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.71) 100%);
    }

    .home_four_module_name {
      position: absolute;
      left: 50%;
      top: 150px;
      transform: translate(-50%, -50%);
      font-size: 34px;
      font-weight: 900;
      color: #ffffff;
      line-height: 40px;
      white-space: nowrap;
    }

    .home_four_module_newsList {
      height: 190px;
      border-top: 0;
      line-height: 40px;
      padding: 15px 24px;

      .home_four_module_newsList_name {
        position: relative;
        padding-left: 20px;
        color: #595959;
        white-space: nowrap;
        /* 防止文本换行 */
        overflow: hidden;
        /* 控制文本溢出时隐藏超出部分 */
        text-overflow: ellipsis;
        cursor: pointer;
      }

      .home_four_module_newsList_name:last-child {
        border: none;
      }

      .home_four_module_newsList_name::before {
        content: "";
        background-color: #595959;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 18px;
      }
    }

    img {
      display: block;
    }

    .swiper-container0 {
      box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);
    }

    .swiper-slide {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
